.cardColumn {
  margin: 0 auto 40px auto;

  &_two {
    margin: 0 0 40px 0;

    &:nth-child(odd) {
      align-self: end;
      margin-right: 33px;
    }
    &:nth-child(even) {
      align-self: start;
    }

    .card {
      width: 100%;
    }
    .body {
      display: flex;
      align-items: center;
    }
    .cont {
      margin-left: 30px;
      transition: none;
    }
    .title {
      margin-bottom: 10px;
      text-align: left;
      transition: none;
    }
    .text {
      display: block;
    }
  }
}

.card {
  width: 210px;
  padding: 30px 15px;

  border: 1px solid #f3f3f3;
  border-radius: 40px;
  transition: box-shadow 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 14px 30px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transform: translateY(-10px);
  }
}

.hurt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  border: 1px solid #ececec;
  border-radius: 7px;

  &.active {
    background: #fef0f0;
  }
  &:hover {
    background: #fef0f0;
  }

  svg {
    width: 20px;
    height: 20px;

    & * {
      stroke: #ececec;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.body {
  width: 100%;
  transition: none;
}

.modelImg {
  width: 133px;
  height: 112px;
  margin: 0 auto;
}

.cont {
  transition: none;
}

.title {
  display: block;

  text-align: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;

  transition: none;
}

.text {
  display: none;

  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 127.5%;
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;

  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.priceWrap {
  margin-right: 10px;
}

.priceName {
  display: block;

  font-weight: 500;
  font-size: 14px;

  color: #bdbdbd;
  text-transform: uppercase;
}

.priceSum {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
}
/*Responsive*/
/* 768px - 992px */
@media screen and (max-width: 992px) {
  .models {
    grid-template-columns: repeat(2, 1fr);

    &_two {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .cardColumn {
    &:nth-child(odd) {
      margin-right: 25px;
    }
    &:nth-child(even) {
      margin-left: 25px;
    }
    &_two {
      &:nth-child(odd) {
        margin-right: 7px;
      }
      &:nth-child(even) {
        margin-left: 7px;
      }
    }
  }
}
/* 576px - 768px */
@media screen and (max-width: 768px) {
  .cardColumn {
    max-width: 500px;

    &_two {
      &:nth-child(odd) {
        margin: 0 auto 25px auto;
      }
      &:nth-child(even) {
        margin: 0 auto 25px auto;
      }
    }
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .cardColumn {
    &:nth-child(odd) {
      margin: 0 auto 20px auto;
    }
    &:nth-child(even) {
      margin: 0 auto 20px auto;
    }
    &_two {
      .body {
        flex-direction: column;
      }
    }
  }
}
