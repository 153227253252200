.gallery {
  margin-top: 64px;
}

.searchRow {
  flex: 1;
  margin-bottom: 62px;
}

.layouts {
  svg * {
    fill: #f2f3f5;
  }
}

.layout {
  margin-right: 20px;
  cursor: pointer;
  &:last-child {
    margin-right: 43px;
  }
}

.activeLayout {
  svg * {
    fill: #000;
  }
}

.searchInput {
  position: relative;

  input {
    height: 36px;
    width: 270px;
    padding: 7px 61px 7px 12px;
    cursor: text;
  }
  svg {
    position: absolute;
    top: 9px;
    right: 12px;
  }
}

.clear {
  position: absolute;
  top: 12.5px;
  right: 10px;

  font-size: 10px;

  color: #bdbdbd;
  cursor: pointer;
}

.autoCompleteList {
  position: absolute;
  top: 35px;
  left: 0;
  width: 270px;
  padding: 12px;

  background: #fff;
  border-top: 1px solid #bdbdbd;
  border-right: 2px solid #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;

  li {
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .notSearch {
    font-size: 10px;
    color: #bdbdbd;
  }
}

.models {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &_two {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*Responsive*/
/* 768px - 992px */
@media screen and (max-width: 992px) {
  .models {
    grid-template-columns: repeat(2, 1fr);

    &_two {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .searchRow {
    flex: 1;
    margin-bottom: 62px;
    flex-wrap: wrap;
  }
  .layouts {
    width: 100%;
    order: 3;
    justify-content: center;
    margin-top: 50px;
  }
  .layout {
    &:last-child {
      margin-right: 0;
    }
  }
  .searchInput {
    order: 2;
  }
  .models {
    &_two {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .searchRow {
    flex-direction: column;
    align-items: flex-start;
  }
  .layouts {
    margin-top: 30px;
  }
  .searchInput {
    width: 100%;

    input {
      width: 100%;
    }
  }
  .models {
    grid-template-columns: repeat(1, 1fr);
  }
}
