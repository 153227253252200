.img {
  width: 32px;
  height: 32px;

  cursor: pointer;
}

.closeWrap {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  border: 1px solid #B5B5B5;
  border-radius: 7px;
  cursor: pointer;
}

.close {
  position: relative;
  width: 11px;
  height: 2px;

  background: #B5B5B5;

  &:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: 2px;

    background: #B5B5B5;
    transform: rotate(90deg);
  }
  &.deg45 {
    transform: rotate(45deg);
  }
}