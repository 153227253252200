.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;

  border: 1px solid #f3f3f3;
  border-radius: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.modelPic {
  width: 70px;
  height: 70px;
}

.counterWrapp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.less,
.more {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 10px;

  border: 1px solid #b5b5b5;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 10px;
    background: #b5b5b5;
  }
}

.count {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.more {
  margin-right: 0;
  margin-left: 10px;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 10px;

    background: #b5b5b5;
    transform: rotate(90deg);
  }
}

.wrapper {
  h4 {
    max-width: 150px;
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    text-transform: none;
  }

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
  }
}
