.basket {
  position: fixed;
  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.line {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 385px;
  height: 100vh;
  padding: 30px;

  background: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.basket_visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.line_visible {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.top {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-bottom: 30px;

  font-weight: 700;
  font-size: 24px;
  text-transform: none;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list {
  flex: 1;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;

  border: 1px solid #f3f3f3;
  border-radius: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.modelPic {
  width: 70px;
  height: 70px;
}

.counterWrapp {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.less,
.more {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 10px;

  border: 1px solid #b5b5b5;
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 10px;
    background: #b5b5b5;
  }
}

.count {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.more {
  margin-right: 0;
  margin-left: 10px;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 10px;

    background: #b5b5b5;
    transform: rotate(90deg);
  }
}

.wrapper {
  h4 {
    max-width: 150px;
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    text-transform: none;
  }

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
  }
}

.bottom {
  margin-top: 20px;
}

.countPrice {
  display: flex;
  align-items: flex-end;

  font-size: 16px;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dashed #dfdfdf;
}

.totalSum {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.btn {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 18px 0;
  margin-top: 18px;

  font-size: 16px;
  font-weight: 600;

  color: #fff;
  background: #9dd458;
  border: none;
  border-radius: 18px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #89b64e;
    .svg {
      transform: translate(25%, -50%);
    }
  }

  span {
    text-align: center;
  }

  .svg {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0, -50%);
    transition: all 0.2s ease-in-out;
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  transition: none;
}

.boxImg {
}

.boxTitle {
  margin-top: 20px;
}

.boxText {
  max-width: 200px;
  margin-top: 10px;
  text-align: center;
}

.btn_Left {
  .svg {
    right: none;
    left: 30px;
  }
  &:hover {
    .svg {
      transform: translate(-25%, -50%);
    }
  }
}

@media screen and (max-width: 576px) {
  .line {
    width: 100%;
  }
}
