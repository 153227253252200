.header {
  margin: 0 auto;
  padding-top: 25px;
  font-weight: 600;
}

.list {
  li {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transition: all 0.2s ease-in-out;
      color: #ff9619;

      span {
        color: #000;
      }
    }
    svg * {
      transition: all 0.55s ease-in-out;
    }
  }
}

.tel {
  transition: all 0.2s ease-in-out;
  font-family: 'Open Sans', sans-serif;
}

.added {
  position: relative;

  span {
    font-family: 'Open Sans', sans-serif;
    transition: all 0.2s ease-in-out;
  }

  svg:first-child {
    margin-right: 10px;
  }
}

.gold {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background: #ff9619;
}

.furniture {
  margin-top: 55px;

  li {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transition: all 0.2s ease-in-out;
      color: #ff9619;
    }
    img {
      width: 181px;
      height: 122px;
    }
    span {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.active {
  color: #ff9619;
}

/*Responsive*/
/* 768px - 992px */
@media screen and (max-width: 992px) {
  .furniture {
    display: none;
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
  .logo {
    width: auto;
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .logo {
    width: 130px;
  }
}
