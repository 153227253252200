.favourite {
  position: relative;
  padding: 40px 0 0 0;
  margin-top: 50px;

  h1 {
    text-transform: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;

    background: #f4f4f4;
  }
}

.row {
  display: flex;
  align-items: center;
}

.back {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 20px;

  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

.favouriteList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 50px;
}

.favouriteList_empty {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 50px;
}

/*Responsive*/
/* 768px - 992px */
@media screen and (max-width: 992px) {
  .favouriteList {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 576px - 768px */
@media screen and (max-width: 768px) {
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .favouriteList {
    grid-template-columns: repeat(1, 1fr);
  }
}
